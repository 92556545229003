import DOMPurify from 'dompurify'

import db, {
  webSearchUrl,
  ytSearchUrl,
  ytUriFromId,
  wavefarmSearchUrl,
  wfUriFromId,
  freesoundSearchUrl,
  freesoundUriFromId,
  archiveUriFromId
} from './database'

export type ResultMedia = {
  uri: string
  tempUrl?: string
  preview?: string
  label?: string
  duration?: number // only use for display
  type?: string
}
export type Result = {
  id: string
  tags?: Array<string> // freesound
  title: string
  artists?: string
  description: string
  images: Array<{
    uri: string
    width?: number
    height?: number
  }>
  media: Array<ResultMedia>
}

export type Results = Array<Result>

export type SearchKind = 'indexdb' | 'yt' | 'wf' | 'fs' | 'archive' | 'web'

export type SearchResponse = {
  total: number
  next?: string
  results: Results
}

// we make sure when we concat any search results that they are unique.
// youtube has a habit of returning the same results with same ID's. If we
// set react keys on them, it causes havoc
export const concatUnique = (a: Results = [], b: Results = []) => b.length
  ? Array.from(b.reduce((acc, r) => {
    acc.set(r.id, r)
    return acc
  }, new Map(a.map(r => [r.id, r]))).values())
  : a

const pp = 25
const urlFromTermNoPage = (term: string): string => wavefarmSearchUrl + '?term=' + encodeURIComponent(term)
const archiveBase = 'https://archive.org/advancedsearch.php?rows=' + pp + '&output=json&fl%5B%5D=identifier,title,source,size,mediatype,description,creator,collection'

// const urlFromTerm: <{[SearchKind]: (term:string)=> Promise<unknown>}> = {
const urlFromTerm = {
  indexdb: (term: string): string => term,
  yt: (term: string): string => ytSearchUrl + '?q=' + encodeURIComponent(term),
  wf: (term: string): string => urlFromTermNoPage(term) + '&page=1',
  fs: (term: string): string => freesoundSearchUrl + '?query=' + encodeURIComponent(term),
  web: (term: string): string => webSearchUrl + '?q=' + encodeURIComponent(term),
  archive: (term: string): string => archiveBase + '&q=' +
    encodeURIComponent(`(mediatype:(audio) OR mediatype:(movies)) AND (title:(${term}) OR description:(${term})) AND NOT access-restricted-item:true`)
}

type FsResult = {
  description: string
  duration: number
  id: number
  images: {
    'spectral_m': string
    'spectral_l': string
    'spectral_bw_m': string
    'spectral_bw_l': string
    'waveform_m': string
    'waveform_l': string
    'waveform_bw_m': string
    'waveform_bw_l': string
  }
  license: string
  name: string
  previews: {
    'preview-lq-ogg': string
    'preview-lq-mp3': string
    'preview-hq-ogg': string
    'preview-hq-mp3': string
  }
  'similar_sounds': string
  tags: Array<string>
}
type FsResponse = {
  count: number
  next?: string
  previous?: string
  results: Array<FsResult>
}

const getFsUriAndType = (r: FsResult) => r.previews?.['preview-hq-mp3']
  ? ({ tempUrl: r.previews['preview-hq-mp3'], type: 'audio/mpeg' })
  : r.previews?.['preview-hq-ogg']
    ? ({ tempUrl: r.previews['preview-hq-ogg'], type: 'audio/ogg' })
    : r.previews?.['preview-lq-mp3']
      ? ({ tempUrl: r.previews['preview-lq-mp3'], type: 'audio/mp3' })
      : ({ tempUrl: r.previews['preview-lq-ogg'], type: 'audio/ogg' })

const convertFs = () => (res: FsResponse): SearchResponse => {
  const u = new URL(res.next || 'http://localhost')
  return {
    total: res.count,
    next: freesoundSearchUrl + u.search,
    results: !(res?.results?.length)
      ? []
      : res.results.map(r => ({
        id: r.id.toString(),
        tags: r.tags || [],
        title: r.name,
        images: [
          {
            uri: r.images.waveform_m,
            width: 900,
            height: 201
          },
          {
            uri: r.images.spectral_m,
            width: 900,
            height: 201
          }
        ],
        description: r.description,
        media: [Object.assign({
          uri: freesoundUriFromId(r.id),
          duration: r.duration,
          preview: r.previews?.['preview-lq-mp3']
        }, getFsUriAndType(r))]
      }))
  }
}
type YtTn = {
  height: number
  width: number
  url: string
}
type YtItem = {
  etag: string
  id: string
  kind: string
  contentDetails: {
    caption: string
    definition: string
    dimension: string
    duration: string // "PT4M24S"
    licensedContent: boolean
    regionRestriction: { blocked: Array<string> }
  }
  snippet: {
    title: string
    categoryId: string
    channelId: string
    channelTitle: string
    defaultAudioLanguage: string
    defaultLanguage: string
    description: string
    liveBroadcastContent: string
    publishedAt: string // "2015-08-05T15:30:01Z"
    tags: Array<string>
    thumbnails: {
      default: YtTn,
      high: YtTn
      maxres: YtTn
      medium: YtTn
      standard: YtTn
    }
  }
}
type YtResponse = {
  etag: string
  items: Array<YtItem>
  kind: string
  nextPageToken: string
  pageInfo: {
    resultsPerPage: number
    totalResults: number
  }
  regionCode: string
}
// const padit = (n: string) => n.padStart(2, '0')
const iso8601DurationRegex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/
// adapted from https://stackoverflow.com/questions/14934089/convert-iso-8601-duration-with-javascript/29153059
const parseISO8601 = (iso8601Duration: string) => {
  const matches = iso8601Duration.match(iso8601DurationRegex)
  return matches
    ? [
      parseInt(matches[2] || '0') * 3.154e+7, // years
      parseInt(matches[3] || '0') * 2.628e+6, // months
      parseInt(matches[4] || '0') * 604800, // weeks
      parseInt(matches[5] || '0') * 86400, // days
      parseInt(matches[6] || '0') * 3600, // hours
      parseInt(matches[7] || '0') * 60, // minutes
      parseInt(matches[8] || '0') // seconds
    ]
      .reduce((acc, x) => acc + x, 0)
    : 0
}
const convertYt = (term: string) => (res: YtResponse): SearchResponse => {
  return {
    total: res.pageInfo.totalResults,
    next: ytSearchUrl + '?q=' + encodeURIComponent(term) + '&pageToken=' + encodeURIComponent(res.nextPageToken),
    results: res.items.map(r => ({
      id: r.id,
      tags: r.snippet?.tags || [],
      title: r.snippet.title,
      images: [{
        uri: r.snippet.thumbnails.default.url,
        width: r.snippet.thumbnails.default.width,
        height: r.snippet.thumbnails.default.height
      }],
      description: r.snippet.description,
      media: [{
        uri: ytUriFromId(r.id),
        duration: parseISO8601(r.contentDetails.duration),
        preview: ytUriFromId(r.id)
      }]
    }))
  }
}

type WfResponse = {
  hits: {
    data: [{
      url: string
      description: string
      artists: [{ id: string, name: string }]
      id: string
      title: string
      updated_at: string // eslint-disable-line camelcase
      mimetype: string
      images: []
    }],
    per_page: string // eslint-disable-line camelcase
    total: number
    current_page: number
    last_page: number
    first_page_url: string // eslint-disable-line camelcase
    next_page_url: string // eslint-disable-line camelcase
    last_page_url: string // eslint-disable-line camelcase
  }
}
const getSafeUrl = (url: string) => url && url.length
  ? url.replace('http://', 'https://')
  : ''

const convertWf = (term: string) => (res: WfResponse): SearchResponse => {
  const page = (res.hits.current_page || 1) + 1
  const lastPage = res.hits.last_page || 1
  return {
    total: res.hits.total,
    next: page <= lastPage
      ? urlFromTermNoPage(term) + `&page=${page}`
      : undefined,
    results: res.hits.data.map(r => ({
      id: r.id,
      title: r.title,
      description: r.description,
      media: [{
        uri: wfUriFromId(r.id),
        tempUrl: getSafeUrl(r.url)
      }],
      images: []
    }))
  }
}

const MEDIA_TYPES = [
  'VBR MP3',
  'MP3',
  'Ogg Vorbis',
  'Ogg Video',
  'MPEG4',
  'Apple Lossless Audio', // m4a
  'h.264 HD', // wtf
  'h.264'
]
const IMAGE_TYPES = [
  'Item Tile',
  'Thumbnail',
  'Item Image',
  'PNG',
  'Spectrogram'
]

// @ts-ignore
const compareImageType = (a, b) => {
  if (a.format && b.format) {
    if (IMAGE_TYPES.indexOf(a.format) > IMAGE_TYPES.indexOf(b.format)) {
      return 1
    }
    return -1
  }
  return 0
}

// @ts-ignore
const compareMediaType = (a, b) => {
  if (a.format && b.format) {
    if (MEDIA_TYPES.indexOf(a.format) > IMAGE_TYPES.indexOf(b.format)) {
      return 1
    }
    return -1
  }
  return 0
}

type ArchiveItemResponse = {
  created: number
  d1?: string // "ia904507.us.archive.org"
  d2?: string // "ia904507.us.archive.org"
  dir: string // "/34/items/Scvtv20-psactgwhowas082909766"
  files: [{
    private: string // false
    name: string // "Scvtv20-psactgwhowas082909725.flv",
    source: string // "original", "derivative", "metadata"
    format: string // "Flash"
    crc32: string // "91942088"
    length: string // "39.18"
    md5: string // "aa827a4ba09103444ffb68a35a0bb995"
    mtime: string // "1248544821"
    sha1: string // "ff8a186b03d7872db3c222b563950618285903c5"
    size: string // "2776255"
    width: string // "640"
    height: string // "480"
  }]
  files_count: number // eslint-disable-line camelcase
  item_last_updated: number // eslint-disable-line camelcase
  item_size: number // eslint-disable-line camelcase
  metadata: {
    collection: [string] // ["bliptv", "vlogs"],
    mediatype: string // "movies",
    resource: string // "movies"
    addeddate: string // "2009-07-25 18:00:24"
    backup_location: string // eslint-disable-line camelcase
    description: string
    identifier: string // "Scvtv20-psactgwhowas082909766"
    publicdate: string // "2009-07-25 18:02:14"
    runtime: string // "00:00:40"
    title: string // "SCVTV.com 7/25/2009 PSA: Canyon Theatre Guild: Who Was That Lady I Saw You With?"
    uploader: string // "info@scvtv.com"
  }
  server: string // 'ia804507.us.archive.org'
  uniq: number // 371227760
  // ['ia804507.us.archive.org', 'ia904507.us.archive.org']
  workable_servers: [string] // eslint-disable-line camelcase
}
type ArchiveResponse = {
  response: {
    docs: [{
      collection: [string]
      creator: string
      description: string
      identifier: string
      title: string

    }]
    numFound: number
    start: number

  }
  responseHeader: {
    QTime: number
    params: {
      rows?: string
      start?: string
      query: string
      fields: string
    }
  }
}

const archiveHandle = (identifier: string, name: string) => encodeURIComponent(identifier) + '/' + encodeURIComponent(name)
const convertArchiveItem = (res: ArchiveItemResponse): Result => ({
  id: res?.metadata?.identifier,
  title: res?.metadata?.title,
  description: res?.metadata?.description,
  media: (res?.files || [])
    .filter(mf => (mf.private !== 'true') && MEDIA_TYPES.indexOf(mf.format) !== -1)
    .sort(compareMediaType)
    .map(f => ({
      uri: archiveUriFromId(archiveHandle(res?.metadata?.identifier, f?.name)),
      tempUrl: 'https://archive.org/cors/' + archiveHandle(res?.metadata?.identifier, f?.name),
      duration: parseFloat(f.length || '0'),
      label: f.name,
      type: f.format
    })),
  images: (res?.files || [])
    .filter(mf => (mf.private !== 'true') && IMAGE_TYPES.indexOf(mf.format) !== -1)
    .sort(compareImageType)
    .map(x => ({
      uri: 'https://archive.org/download/' + archiveHandle(res?.metadata?.identifier, x.name),
      height: x.height ? parseInt(x.height) : undefined,
      width: x.width ? parseInt(x.width) : undefined
    }))
})

type WebResponse = {
  results: Array<string>
}

const convertWeb = () => (res: WebResponse): SearchResponse => ({
  total: res?.results?.length || 0,
  next: undefined,
  results: res.results?.length
    ? res.results.map(url => ({
      id: url,
      title: url,
      images: [],
      description: '',
      media: [{
        uri: url,
        preview: url
      }]
    }))
    : []
})

// @ts-ignore
const convert = (term: string, kind: SearchKind): (_: unknown) => SearchResponse => kind === 'fs'
  ? convertFs()
  : kind === 'yt'
    ? convertYt(term)
    : kind === 'web'
      ? convertWeb()
      : convertWf(term)

const jsonOk = (r: Response) => (json: JSON & { error: string }) => r.ok
  ? json
  : Promise.reject(new Error(json.error || r.statusText))

export const getJson = (url: string): Promise<unknown> => {
  return window.fetch(url)
    .then(r => r.json()
      .then(jsonOk(r))
    )
}

const hasMore = (sdata: ArchiveResponse) => {
  const total = sdata?.response?.numFound
  const perPage = parseInt(sdata.responseHeader?.params?.rows || pp.toString())
  const start = parseInt(sdata.responseHeader?.params?.start || '0')
  return start + perPage < total
}

const getPageNum = (sdata: ArchiveResponse) => {
  const perPage = parseInt(sdata.responseHeader?.params?.rows || pp.toString())
  const start = parseInt(sdata.responseHeader?.params?.start || '0')
  return start === 0
    ? 1
    : hasMore(sdata)
      ? Math.floor(start / perPage) + 1
      : Math.floor(start / perPage) // current page
}
const getArchive = (term: string, kind: SearchKind, next?: string): Promise<SearchResponse> => getJson(next || urlFromTerm[kind](term))
  .then(res => res as ArchiveResponse)
  .then(res => Promise.all((res?.response?.docs || [])
    .map(d => getJson(`https://archive.org/metadata/${d.identifier}?output=json`)
      // @ts-ignore
      .then((t: ArchiveItemResponse) => {
        // console.log(t)
        if (t?.metadata.description) {
          t.metadata.description = DOMPurify.sanitize(t.metadata.description, { FORBID_TAGS: ['style'], FORBID_ATTR: ['style'] })
        }
        return t
      })
      .then(convertArchiveItem)
    ))
    .then((results) => ({
      total: res.response.numFound,
      next: urlFromTerm.archive(term) + '&page=' + (getPageNum(res) + 1),
      results
    }))

  )

const checkAudioUrl = (term: string) => new Promise((resolve, reject) => {
  const a = new window.Audio()
  const onLoad = () => resolve('good')
  const onError = () => reject()
  a.addEventListener('loadeddata', onLoad)
  a.addEventListener('error', onError)
  a.preload = 'metadata'
  a.src = term
})

const fetch = (term: string, kind: SearchKind, next?: string): Promise<SearchResponse> => kind !== 'archive'
  ? getJson(next || urlFromTerm[kind](term))
    .then(convert(term, kind))
  : getArchive(term, kind, next)

const searchIdb = async (term: string) => {
  return db.buffers.search(term)
    .then(results => ({
      total: results.length,
      next: '',
      results: results.map(r => ({
        id: r.uri,
        title: r.title,
        images: [],
        description: r.description,
        media: [r]
      }))
    }))
}

// @ts-ignore
export const fetchData = (term: string, kind: SearchKind, next?: string): Promise<SearchResponse> => kind === 'web'
  ? checkAudioUrl(term)
    .then(() => ({
      total: 1,
      next: '',
      results: [{
        id: term,
        title: term,
        images: [],
        description: '',
        media: [{
          uri: term,
          preview: term
        }]
      }]
    }))
    .catch(() => fetch(term, kind, next))
  : kind === 'indexdb'
    ? searchIdb(term)
    : fetch(term, kind, next)
