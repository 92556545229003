import { setup, assertEvent, assign, sendParent, forwardTo, fromCallback } from 'xstate'
import { getSetColors, generateId } from './utils'
import { Participant, Participants, RtpForwardInfo } from './types'


const setBitrateSend = (pc: RTCPeerConnection, bitrateSend: number) => {
  const haveWhatWeNeed = 'RTCRtpSender' in window && 'setParameters' in window.RTCRtpSender.prototype
  if (haveWhatWeNeed) {
    const senders = pc.getSenders()
    const sender = senders.length ? senders[0] : null
    const params = sender ? sender.getParameters() : null
    if (sender && params) {
      if (!params.encodings?.length) {
        console.log('creating encodings to set bitrateSend', params.encodings)
        params.encodings = [{}]
      }

      params.encodings[0].maxBitrate = bitrateSend
      // console.log('setting bitrateSend', params.encodings)
      return sender.setParameters(params)
    }
  }
  return Promise.resolve()
}
const getIceServersFromString = (s: string) => {
  try {
    return JSON.parse(s)
  } catch (e) {
    console.error('iceServer error', e)
    console.log('iceServer error JSON', s)
    return []
  }
}

// @ts-expect-error: Property 'env' does not exist on type 'ImportMeta'. [2339]
const _getIceServers = () => import.meta.env?.VITE_ICE_SERVERS
  // @ts-expect-error: [2339]
  ? getIceServersFromString(import.meta.env?.VITE_ICE_SERVERS)
  : [
    {
      urls: 'stun:stun.l.google.com:19302'
    }
  ]

const getIceServers = () => {
  const s = _getIceServers()
  console.log('iceServers', s)
  return s
}

const pickPart = ({
  feed = "unknown",
  display = "unknown",
  setup = false,
  muted = false,
  suspended = false,
  talking = false,
  colors = { fg: '#ffffff', bg: '#000000' }
}: Partial<Participant>): Participant => ({ display, feed, setup, muted, suspended, talking, colors })

const setParticipants = (
  participants: Participants,
  newParticipants: Array<Participant>
): Participants => new Map(newParticipants.reduce((acc, p) => p.feed
  ? acc.set(p.feed, pickPart(getSetColors(acc, p)))
  : acc
  , participants || new Map()))

const addParticipant = (
  participants: Participants,
  event: Partial<Participant>
): Participants => {
  const p = event.feed
    ? pickPart(
      getSetColors(
        participants,
        Object.assign({}, participants.get(event.feed), event)
      )
    )
    : null
  const m = p && event.feed
    ? participants.set(event.feed, p)
    : participants
  return m
}

const deleteParticipant = (participants: Participants, event: { feed: string, room: string }) => {
  participants.delete(event.feed)
  return new Map(participants)
}
const setTalking = (participants: Participants, event: { feed: string, talking: boolean }) => {
  const p = pickPart(getSetColors(participants, { feed: event.feed, talking: event.talking }))
  return new Map(participants.set(event.feed, p))
}

type SendOfferRcvEvents = RtcConfigured | RtcSetOutboundBitrate
const sendOffer = fromCallback<SendOfferRcvEvents, RtcContext>(({ sendBack, receive, input }) => {
  if (!input?.localStream.getTracks().length) {
    sendBack({ type: 'RTC.ERROR', connectionState: 'disconnected', iceConnectionState: 'disconnected' })
  }
  const pc = new RTCPeerConnection({
    // iceTransportPolicy: 'relay', // use this for testing TURN server
    iceServers: getIceServers()
  })
  receive((event) => {
    console.log('got PC event', event)
    if (event.type === 'RTC.CONFIGURED') {
      if (pc && event.jsep) {
        pc.setRemoteDescription(event.jsep)
          //.then(() => console.log('set the remote description', event.jsep?.sdp))
          .catch(() => sendBack({ type: 'RTC.ERROR', connectionState: pc.connectionState, iceConnectionState: pc.iceConnectionState }))
      }

    }
    // we try to set the outgoing bitrate. works on Safari and Chrome, NOT Firefox
    if (event.type === 'RTC.SET_OUTBOUND_BITRATE') {
      setBitrateSend(pc, event.bitrateSend)
        .catch(console.error)
    }

  })

  input?.localStream.getTracks().forEach(track => pc.addTrack(track, input.localStream))

  const trickle = (candidate: RTCIceCandidate) => {
    // console.log('trickle', candidate)
    const data = candidate ? { candidate } : {}
    const msg = candidate ? 'trickle' : 'trickle-complete'
    sendBack({ type: 'RTC.STATE_CHANGE', connectionState: pc.connectionState, iceConnectionState: pc.iceConnectionState })
    sendBack({
      type: 'WS.SEND_JANUS',
      data: { msg, data }
    }) //, { to: 'wsConnected' })
  }
  const onNegNeeded = (event: Event) => console.log('pc.onnegotiationneeded', event)
  const onIceCandidate = (event: RTCPeerConnectionIceEvent) => event?.candidate ? trickle(event.candidate) : null
  const stateChange = () => {
    console.log('stateChange', pc.iceConnectionState, pc.connectionState)
    return pc.iceConnectionState === 'failed' || pc.iceConnectionState === 'closed' || pc.connectionState === 'failed'
      ? sendBack({ type: 'RTC.ERROR', iceConnectionState: pc.iceConnectionState, connectionState: pc.connectionState })
      : sendBack({ type: 'RTC.STATE_CHANGE', iceConnectionState: pc.iceConnectionState, connectionState: pc.connectionState })
  }

  const onIceConnectionStateChange = () => {
    console.log('ICE iceConnection state changed', pc.iceConnectionState)
    return stateChange()
  }
  const onConnectionStateChange = () => {
    console.log('connection state changed', pc.connectionState)
    return stateChange()
  }
  const onTrack = (event: RTCTrackEvent) => {
    console.log('onTrack', event)
    event.track.onunmute = () => {
      sendBack({ type: 'RTC.STATE_CHANGE', connectionState: pc.connectionState, iceConnectionState: pc.iceConnectionState })
      // console.log('track.onunmute', evt)
      // send({ type: 'SET_REMOTE_STREAM', remoteStream: evt.target })
    }
    event.track.onmute = () => sendBack({ type: 'RTC.STATE_CHANGE', connectionState: pc.connectionState, iceConnectionState: pc.iceConnectionState })
    event.track.onended = () => sendBack({ type: 'RTC.ERROR', connectionState: pc.connectionState, iceConnectionState: pc.iceConnectionState })
    const remoteStream = event.streams[0]
    if (remoteStream) {
      console.log('pc.ontrack, sending remote stream', remoteStream)
      sendBack({ type: 'RTC.SET_REMOTE_STREAM', remoteStream })
    }
  }

  pc.addEventListener('icecandidateerror', (e) => console.log('icecandidateerror', e))
  pc.addEventListener('signalingstatechange', (e) => console.log('signalingstatechange', e, pc.signalingState))

  pc.addEventListener('negotiationneeded', onNegNeeded)
  pc.addEventListener('icecandidate', onIceCandidate)
  pc.addEventListener('connectionstatechange', onConnectionStateChange)
  pc.addEventListener('iceconnectionstatechange', onIceConnectionStateChange)
  pc.addEventListener('track', onTrack)

  sendBack({ type: 'RTC.SET_PC', pc })

  pc.createOffer()
    .then(async (offer: RTCSessionDescriptionInit) => {
      console.log('offer sdp', offer.sdp)
      const modified = {
        sdp: offer.sdp?.indexOf('stereo=1') === -1
          //  Opus DTX is enabled it will encode silence at a lower bitrate by reducing the number of frames sent over the network
          ? offer.sdp.replace('useinbandfec=1', 'useinbandfec=1;stereo=1;usedtx=1')
          : offer.sdp,
        type: offer.type
      }
      // modified.sdp = updateBandwidthRestriction(modified.sdp || '', context.bitrateSend / 1000)
      // console.log('create offer OK', modified.sdp)
      return pc.setLocalDescription(modified)
        .then(() => sendBack({
          type: 'WS.SEND_JANUS',
          data: { msg: 'configure', data: { jsep: offer } }
        }))
    })
    .then(() => setBitrateSend(pc, input.bitrateSend))
    .catch(console.error)

  return () => {
    pc.removeEventListener('negotiationneeded', onNegNeeded)
    pc.removeEventListener('icecandidate', onIceCandidate)
    pc.removeEventListener('iceconnectionstatechange ', onIceConnectionStateChange)
    pc.removeEventListener('connectionstatechange ', onConnectionStateChange)
    pc.removeEventListener('track', onTrack)
  }
})

export type RtcContext = {
  room: string
  feed?: string
  secret?: string
  // 0-20, a percentage of the expected loss (capped at 20%), only needed in case FEC is used;
  // optional, default is 0 (FEC disabled even when negotiated)
  expected_loss: number // eslint-disable-line camelcase
  // number of packets to buffer before decoding this participant (default=room value, or DEFAULT_PREBUFFERING),
  prebuffer: number
  // bitrate to use for the incoming Opus stream in bps; optional, default=0(libopus decides),
  bitrate: number
  // this is the bitrate we set for the outgoing stream
  bitrateSend: number
  // 0-10, Opus-related complexity to use, the higher the value, the better the quality (but more CPU); optional, default is 4,
  quality: number

  pc?: RTCPeerConnection
  connectionState?: RTCPeerConnectionState
  iceConnectionState?: RTCIceConnectionState
  localStream: MediaStream
  remoteStream?: MediaStream
  participants: Participants
  forwards: Array<RtpForwardInfo>
}

export type RtcJoin = { type: 'RTC.JOIN', display: string, room: string, muted?: boolean }
export type RtcJoined = { type: 'RTC.JOINED', room: string, feed: string, display: string, participants: Array<Participant> }
export type RtcLeave = { type: 'RTC.LEAVE' }
export type RtcRtpFwdList = { type: 'RTC.RTP_FWD_LIST' }
export type RtcRtpFwdListed = { type: 'RTC.RTP_FWD_LISTED', room: string, forwarders: Array<RtpForwardInfo> }
export type RtcRecordingEnable = { type: 'RTC.RECORDING_ENABLE', record: boolean }

export type RtcConfigure = {
  type: 'RTC.CONFIGURE',
  bitrate?: number,
  quality?: number,
  prebuffer?: number,
  expected_loss?: number, // eslint-disable-line camelcase
  // number of packets to buffer before decoding this participant (default=room value, or DEFAULT_PREBUFFERING),
  display?: string,
  jsep?: RTCSessionDescriptionInit
}
export type RtcConfigured = {
  type: 'RTC.CONFIGURED',
  room: string,
  feed: string,
  jsep?: RTCSessionDescriptionInit,
  display?: string,
  bitrate?: number,
  quality?: number,
  prebuffer?: number
}

type MuteSuspendBase = { feed: string } // participant id
export type RtcSuspend = MuteSuspendBase & { type: 'RTC.SUSPEND' }
export type RtcSuspended = MuteSuspendBase & { type: 'RTC.SUSPENDED' }
export type RtcResume = MuteSuspendBase & { type: 'RTC.RESUME' }
export type RtcResumed = MuteSuspendBase & { type: 'RTC.RESUMED' }

export type RtcMute = MuteSuspendBase & { type: 'RTC.MUTE' }
export type RtcMuted = MuteSuspendBase & { type: 'RTC.MUTED' }
export type RtcUnmute = MuteSuspendBase & { type: 'RTC.UNMUTE' }
export type RtcUnmuted = MuteSuspendBase & { type: 'RTC.UNMUTED' }

export type RtcTalking = { type: 'RTC.TALKING', room: string, feed: string, talking: boolean }
export type RtcPeerJoined = { type: 'RTC.PEER_JOINED' } & Participant
export type RtcPeerConfigured = { type: 'RTC.PEER_CONFIGURED' } & Participant
export type RtcPeerLeaving = { type: 'RTC.PEER_LEAVING', room: string, feed: string }
export type RtcPeerTalking = { type: 'RTC.PEER_TALKING', room: string, feed: string, talking: boolean }
export type RtcSetOutboundBitrate = { type: 'RTC.SET_OUTBOUND_BITRATE', bitrateSend: number }

export type SendRtcData = RtcJoin
  | RtcJoined
  | RtcLeave
  | RtcConfigure
  | RtcConfigured
  | RtcTalking
  | RtcPeerJoined
  | RtcPeerConfigured
  | RtcPeerTalking
  | RtcSuspend
  | RtcResume
  | RtcMute
  | RtcUnmute


export type RtcSend = {
  type: 'RTC.SEND',
  data: SendRtcData
}

export type SetRemoteStream = { type: 'RTC.SET_REMOTE_STREAM', remoteStream: MediaStream }
type RtcStateChange = { type: 'RTC.STATE_CHANGE', connectionState: RTCPeerConnectionState, iceConnectionState: RTCIceConnectionState }
type SetPc = { type: 'RTC.SET_PC', pc: RTCPeerConnection }
export type RtcEvents = RtcJoin
  | RtcJoined
  | RtcSuspend
  | RtcSuspended
  | RtcResume
  | RtcResumed
  | RtcMute
  | RtcMuted
  | RtcUnmute
  | RtcUnmuted
  | RtcLeave
  | RtcRtpFwdList
  | RtcRtpFwdListed
  | RtcRecordingEnable
  | RtcConfigure
  | RtcConfigured
  | RtcTalking
  | RtcPeerJoined
  | RtcPeerLeaving
  | RtcPeerConfigured
  | RtcPeerTalking
  | RtcSend
  | RtcStateChange
  | RtcSetOutboundBitrate
  | SetRemoteStream
  | SetPc
  | { type: 'RTC.CLOSE' }
  | { type: 'RTC.ERROR', connectionState: RTCPeerConnectionState, iceConnectionState: RTCIceConnectionState }
  | { type: 'RTC.SET_PARTICIPANTS', participants: Participants }

export const RtcMachine = setup({
  types: {
    context: {} as RtcContext,
    events: {} as RtcEvents
  },
  actors: {
    sendOffer
  },
  actions: {
    announceDisconnect: ({ context }) => console.log('webrtc disconnected', context),
    announceJoining: ({ context }) => console.log('webrtc joining', context),
    announceJoined: ({ context }) => console.log('webrtc joined', context),
    rtcJoin: sendParent(({ context, event }) => ({
      type: 'WS.SEND_JANUS',
      data: {
        msg: 'join',
        data: {
          room: (event as RtcJoin).room,
          feed: generateId(16),
          display: (event as RtcJoin).display,
          bitrate: (context.bitrate || 0),
          quality: context.quality,
          expected_loss: context.expected_loss,
          prebuffer: context.prebuffer
        }
      }
    })),
    rtcJoined: assign(({ context, event }) => {
      console.log('got rtcJoined', event, context)
      assertEvent(event, 'RTC.JOINED')
      return {
        display: event.display,
        feed: event.feed,
        participants: setParticipants(
          context.participants,
          event.participants // .concat([pickPart({ feed: event.feed, display: event.display })])
        )
      }
    }),
    rtcRecordingEnable: sendParent(({ context, event }) => ({
      type: 'WS.SEND_JANUS',
      data: {
        msg: 'recording-enable',
        data: {
          room: context.room,
          secret: context.secret,
          record: (event as RtcRecordingEnable).record,
          filename: (event as RtcRecordingEnable).record
            ? context.room.replace(/\s/g, '_') + '_' + Date.now() + '.wav'
            : undefined
        }
      }
    })),

    setForwards: assign({
      forwards: ({ event }) => (event as RtcRtpFwdListed).forwarders || []
    }),

    rtcLeave: sendParent({ type: 'WS.SEND_JANUS', data: { msg: 'leave' } }),
    setConfigure: assign(({ context, event }) => {
      assertEvent(event, 'RTC.CONFIGURE')
      return {
        bitrate: event.bitrate || context.bitrate,
        quality: event.quality || context.quality,
        expected_loss: event.expected_loss || context.expected_loss,
        prebuffer: event.prebuffer || context.prebuffer
        // participants: addParticipant(context.participants, event)
      }
    }),
    rtcConfigure: sendParent(({ context, event }) => {
      assertEvent(event, ['RTC.CONFIGURE', 'RTC.JOINED'])
      const e = (event as RtcConfigure)
      return {
        type: 'WS.SEND_JANUS',
        data: {
          msg: 'configure',
          data: {
            bitrate: e.bitrate || context.bitrate,
            quality: e.quality || context.quality,
            expected_loss: e.expected_loss || context.expected_loss,
            prebuffer: e.prebuffer || context.prebuffer,
            display: event.display // || (context as MainContext).display
          }
        }
      }
    }),
    rtcConfigured: assign(({ context, event }) => {
      assertEvent(event, 'RTC.CONFIGURED')
      console.log('rtc Configured action', event)
      return ({
        feed: event.feed || context.feed,
        room: event.room || context.room,
        bitrate: event.bitrate || context.bitrate,
        quality: event.quality || context.quality,
        participants: addParticipant(context.participants, event)

      })
    }),
    rtcSetBitrateSend: assign(({ context, event }) => {
      console.log('rtc set send bitrate action', event)
      return ({ bitrateSend: (event as RtcSetOutboundBitrate).bitrateSend || context.bitrateSend })
    }),

    rtcTalking: assign({ participants: ({ context, event }) => setTalking(context.participants, event as RtcTalking) }),
    rtcPeerJoined: assign({ participants: ({ context, event }) => addParticipant(context.participants, event as RtcPeerJoined) }),
    rtcPeerLeaving: assign({ participants: ({ context, event }) => deleteParticipant(context.participants, event as RtcPeerLeaving) }),
    rtcPeerConfigured: assign({ participants: ({ context, event }) => addParticipant(context.participants, event as RtcPeerConfigured) }),

    // setDisplay: assign({ pc: (_context, event) => event.display }),
    setPc: assign({
      pc: ({ event }) => {
        assertEvent(event, 'RTC.SET_PC')
        return (event as SetPc).pc
      }
    }),
    setRemoteStream: assign({
      remoteStream: ({ event }) => {
        return (event as SetRemoteStream).remoteStream
      }
    }),
    setConnectionState: assign(({ event }) => ({
      connectionState: (event as RtcStateChange).connectionState,
      iceConnectionState: (event as RtcStateChange).iceConnectionState
    })),
    closePC: assign(({ context }) => {
      if (!context.pc) return {}
      context.pc.getSenders().forEach(sender => {
        if (sender.track) { sender.track.stop() }
      })
      context.pc.getReceivers().forEach(receiver => {
        if (receiver.track) { receiver.track.stop() }
      })
      context.pc.close()
      return {
        feed: '',
        participants: new Map()
      }
    }),
    rtcSuspend: sendParent(({ context, event }) => ({
      type: 'WS.SEND_JANUS',
      data: {
        msg: 'suspend',
        data: {
          room: context.room,
          feed: (event as RtcSuspend).feed,
          secret: context.secret
        }
      }
    })),
    rtcSuspended: assign({
      participants: ({ context, event }) => {
        assertEvent(event, 'RTC.SUSPENDED')
        return event.feed && context.participants.get(event.feed)
          ? context.participants.set(event.feed, Object.assign({}, context.participants.get(event.feed), { suspended: true }))
          : context.participants
      }
    }),
    rtcResume: sendParent(({ context, event }) => ({
      type: 'WS.SEND_JANUS',
      data: {
        msg: 'resume',
        data: {
          room: context.room,
          feed: (event as RtcResumed).feed,
          secret: context.secret
        }
      }
    })),
    rtcResumed: assign({
      participants: ({ context, event }) => {
        assertEvent(event, 'RTC.RESUMED')
        return event.feed && context.participants.get(event.feed)
          ? context.participants.set(event.feed, Object.assign({}, context.participants.get(event.feed), { suspended: false }))
          : context.participants
      }
    }),
    rtcMute: sendParent(({ context, event }) => ({
      type: 'WS.SEND_JANUS',
      data: {
        msg: 'mute',
        data: {
          room: context.room,
          feed: (event as RtcMuted).feed,
          secret: context.secret
        }
      }
    })),
    rtcMuted: assign({
      participants: ({ context, event }) => {
        assertEvent(event, 'RTC.MUTED')
        return event.feed && context.participants.get(event.feed)
          ? context.participants.set(event.feed, Object.assign({}, context.participants.get(event.feed), { muted: true }))
          : context.participants
      }
    }),
    rtcUnmute: sendParent(({ context, event }) => ({
      type: 'WS.SEND_JANUS',
      data: {
        msg: 'unmute',
        data: {
          room: context.room,
          feed: (event as RtcUnmute).feed,
          secret: context.secret
        }
      }
    })),
    rtcUnmuted: assign({
      participants: ({ context, event }) => {
        assertEvent(event, 'RTC.UNMUTED')
        return event.feed && context.participants.get(event.feed)
          ? context.participants.set(event.feed, Object.assign({}, context.participants.get(event.feed), { muted: false }))
          : context.participants
      }
    }),

  },

}).createMachine({
  context: ({ input }) => {
    console.log('rtc init', input)
    return input as RtcContext
  },
  initial: 'disconnected',
  states: {
    disconnected: {
      entry: ['announceDisconnect', 'closePC'],
      on: {
        'RTC.RECORDING_ENABLE': { actions: 'rtcRecordingEnable' },
        'RTC.JOIN': {
          actions: 'rtcJoin',
          target: 'joining'
        }
      }
    },
    joining: {
      entry: 'announceJoining',
      on: {
        'RTC.ERROR': 'disconnected',
        'RTC.JOINED': {
          actions: 'rtcJoined',
          target: 'joined'
        }
      }
    },
    joined: {
      entry: ['announceJoined', 'rtcConfigure'],
      invoke: {
        id: 'sendOffer',
        src: 'sendOffer',
        input: ({ context }) => context
      },
      on: {
        'WS.SEND_JANUS': { actions: sendParent(({ event }) => event) },
        'RTC.LEAVE': {
          target: 'disconnected',
          actions: ['rtcLeave']
        },
        'RTC.ERROR': {
          target: 'disconnected',
          actions: ['setConnectionState']
        },
        'RTC.STATE_CHANGE': { actions: 'setConnectionState' },
        'RTC.CONFIGURE': { actions: ['setConfigure', 'rtcConfigure'] },
        'RTC.CONFIGURED': {
          actions: ['rtcConfigured', forwardTo('sendOffer')],
        },
        'RTC.SET_OUTBOUND_BITRATE': {
          actions: ['rtcSetBitrateSend', forwardTo('sendOffer')],
        },


        'RTC.RECORDING_ENABLE': { actions: 'rtcRecordingEnable' },


        'RTC.SUSPEND': { actions: 'rtcSuspend' },
        'RTC.SUSPENDED': { actions: 'rtcSuspended' },
        'RTC.RESUME': { actions: 'rtcResume' },
        'RTC.RESUMED': { actions: 'rtcResumed' },

        'RTC.MUTE': { actions: 'rtcMute' },
        'RTC.MUTED': { actions: 'rtcMuted' },
        'RTC.UNMUTE': { actions: 'rtcUnmute' },
        'RTC.UNMUTED': { actions: 'rtcUnmuted' },


        'RTC.TALKING': { actions: 'rtcTalking' },
        'RTC.PEER_TALKING': { actions: 'rtcTalking' },
        'RTC.PEER_JOINED': { actions: 'rtcPeerJoined' },
        'RTC.PEER_LEAVING': { actions: 'rtcPeerLeaving' },
        'RTC.PEER_CONFIGURED': { actions: 'rtcPeerConfigured' },

        'RTC.SET_REMOTE_STREAM': {
          actions: [
            'setRemoteStream',
            sendParent(({ event }) => event)
          ]
        }
      }
    }
  },
  on: {
    'RTC.SET_PC': { actions: 'setPc' },
    // 'RTC.SEND': { actions: ({ event }) => sendTo('sendOffer', event.data) },
    'RTC.RTP_FWD_LISTED': { actions: 'setForwards' }
  }
})
