import {
  setup,
  assign,
  sendParent,
  fromPromise,
  fromCallback,
  ActorRefFrom,
  sendTo
} from 'xstate'
import {
} from './nodes'
import { generateId } from './utils'
import { SearchMachine } from './search.machine'
import { PlaylistMachine, LoadMedia } from './playlist.machine'
// import { RecService, createRecordMachine } from './record.machine'
import { AnnounceDlKeys } from './download.machine'


import { BaseTrack } from './types'
import {
  NodesContext,
  NodesEvent,
  vol,
  pan,
  eqHi,
  eqMd,
  eqLo,
  master,
  broadcast,
  toggleBig,
  disconnectNodes,
  fadeOut,
  createNodes,
  connectToNodes,
  connectNodesToOut
} from './nodes'
import {
  Media,
  getMediaUrl,
  canProxy,
  proxyUrl,
} from './database'


const isString = (v: unknown) => (typeof v === 'string' || v instanceof String)
const hasLen = (v: string | undefined): boolean => !!(v && isString(v) && v.trim().length > 0)
const getArtist = (m: Media) => hasLen(m.artist)
  ? m.artist + ': '
  : ''

export const getTitle = (m: Media): string => hasLen(m.title)
  ? getArtist(m) + m.title
  : '(untitled)'

const getBuffersFromMediaEl = (mediaElement?: HTMLMediaElement) => {
  const b = mediaElement?.buffered || new TimeRanges()
  const buffers = []
  if (b && b.length) {
    for (let i = 0; i < b.length; i++) {
      buffers.push({ start: b.start(i), end: b.end(i) })
    }
  }
  return buffers
}

export const scrubPlaylist = (playlist: Array<Media>, index: number) => playlist.map((i, idx) => idx === index
  ? Object.assign(i, { isQueued: true })
  : Object.assign(i, { isQueued: false })
)

type BufferObj = {
  start: number
  end: number
}

export type PlayerContext = BaseTrack & NodesContext & {
  playlistRef: ActorRefFrom<typeof PlaylistMachine>
  searchRef: ActorRefFrom<typeof SearchMachine>
  mediaNode: MediaElementAudioSourceNode
  // player stuff
  currentTime: number
  duration: number
  loop: boolean
  playing: boolean
  waiting: boolean
  buffers: Array<BufferObj>
  error?: string
  blobUrl?: string
}

type PlayerInput = Omit<PlayerContext, 'playlistRef' | 'searchRef'> & {
  playlistId: string
}

type SetTimeDuration = { type: 'SET_TIME_DURATION', currentTime: number, duration: number }
type SetInfoOnLoad = { type: 'SET_INFO_ON_LOAD', currentTime: number, duration: number, buffers: Array<BufferObj> }
export type LoadIdx = { type: 'LOAD_IDX', idx: number }
type SetTime = { type: 'SET_TIME', time: number }
type SetRate = { type: 'SET_RATE', rate: number }
type BlobUrlSet = { type: 'BLOB_URL_SET', blobUrl: string }
type SetProgress = { type: 'SET_PROGRESS', buffers: Array<BufferObj> }

export type PlayerEvents = NodesEvent
  | LoadMedia
  | SetTimeDuration
  | SetInfoOnLoad
  | LoadIdx
  | SetTime
  | SetRate
  | SetProgress
  | BlobUrlSet
  | AnnounceDlKeys
  | { type: 'PAUSE' }
  | { type: 'STOP' }
  | { type: 'PLAY' }
  | { type: 'TOGGLE_LOOP' }
  | { type: 'BUFFER_LOW' }
  | { type: 'TRACK_END' }
  | { type: 'ERROR' }
  | { type: 'CLEAR_ERROR' }
  | { type: 'REMOVE_TRACK', id: string }
  | { type: 'BLOB_URL_CLEAR' }
  | { type: 'DONE' }

const loadSrc = fromCallback<LoadMedia, { media: Media, mediaNode: MediaElementAudioSourceNode, blobUrl?: string }>(({ sendBack, input }) => {

  const m = input.media
  const mediaElement = input.mediaNode.mediaElement
  const onLoad = (e: Event) => {
    const a = e.target as HTMLMediaElement
    // console.log('onload duration', e.target.duration, m.duration)
    // ATTENTION: sometimes the duration comes back as Infinity
    sendBack({
      type: 'SET_INFO_ON_LOAD',
      duration: isFinite(a.duration) ? a.duration : m.duration || Infinity,
      currentTime: a.currentTime || 0,
      buffers: getBuffersFromMediaEl(a)
    })
  }
  const onError = (e: Event) => {
    const a = e.target as HTMLMediaElement
    if (a.error?.code === 4 && canProxy(a.src)) {
      console.log('proxying', a.src)
      mediaElement.src = proxyUrl(a.src)
    } else {
      console.log('got error in loadSrc service', a.error, a.src)
      sendBack({ type: 'ERROR' })
    }
  }
  mediaElement.addEventListener('loadedmetadata', onLoad)
  mediaElement.addEventListener('loadeddata', onLoad)
  mediaElement.addEventListener('error', onError)
  const controller = new AbortController()
  getMediaUrl(controller.signal)(m)
    .then(({ isBlob, src }) => {
      if (input.blobUrl) {
        window.URL.revokeObjectURL(input.blobUrl)
      }
      if (isBlob) {
        sendBack({ type: 'BLOB_URL_SET', blobUrl: src })
      }
      mediaElement.src = src
    })
    .catch(console.error)

  return () => {
    controller.abort()
    mediaElement.removeEventListener('loadedmetadata', onLoad)
    mediaElement.removeEventListener('loadeddata', onLoad)
    mediaElement.removeEventListener('error', onError)
  }
})
const createMediaListeners = fromCallback<SetInfoOnLoad, { mediaNode: MediaElementAudioSourceNode }>(({ sendBack, input }) => {
  const mediaElement = input.mediaNode.mediaElement
  const onTimeDurationUpdate = (e: Event) => {
    const a = e.target as HTMLMediaElement
    return sendBack({
      type: 'SET_TIME_DURATION',
      duration: a.duration,
      currentTime: a.currentTime
    })
  }
  const onEnded = () => sendBack({ type: 'TRACK_END' })
  const onWaiting = () => sendBack({ type: 'BUFFER_LOW' })
  const onPlaying = () => sendBack({ type: 'PLAY' })
  const onError = () => sendBack({ type: 'ERROR' })

  const onProgress = (e: Event) => {
    return sendBack({ type: 'SET_PROGRESS', buffers: getBuffersFromMediaEl(e.target as HTMLMediaElement) })
  }
  mediaElement.addEventListener('progress', onProgress)
  mediaElement.addEventListener('timeupdate', onTimeDurationUpdate)
  mediaElement.addEventListener('durationchange', onTimeDurationUpdate)
  mediaElement.addEventListener('ended', onEnded)
  mediaElement.addEventListener('waiting', onWaiting)
  mediaElement.addEventListener('playing', onPlaying)
  mediaElement.addEventListener('error', onError)
  return () => {
    mediaElement.removeEventListener('progress', onProgress)
    mediaElement.removeEventListener('timeupdate', onTimeDurationUpdate)
    mediaElement.removeEventListener('durationchange', onTimeDurationUpdate)
    mediaElement.removeEventListener('ended', onEnded)
    mediaElement.removeEventListener('waiting', onWaiting)
    mediaElement.removeEventListener('playing', onPlaying)
    mediaElement.removeEventListener('error', onError)
  }
})

export const playerMachine = setup({
  types: {
    context: {} as PlayerContext,
    events: {} as PlayerEvents,
    input: {} as PlayerInput
  },
  actions: {
    resume: sendParent({ type: 'RESUME' }),
    toggleBig: sendParent(({ context }) => toggleBig(context)),
    toggleLoop: assign({
      loop: ({ context }) => {
        const loop = !context.mediaNode.mediaElement.loop
        context.mediaNode.mediaElement.loop = loop
        return loop
      }
    }),
    setProgress: assign({
      buffers: ({ event }) => (event as SetProgress).buffers
    }),
    setStop: assign({
      currentTime: ({ context }) => {
        context.mediaNode.mediaElement.pause()
        context.mediaNode.mediaElement.currentTime = 0
        return context.mediaNode.mediaElement.currentTime
      }
    }),
    setPause: assign({
      currentTime: ({ context }) => {
        context.mediaNode.mediaElement.pause()
        return context.mediaNode.mediaElement.currentTime
      },
      duration: ({ context }) => context.mediaNode.mediaElement.duration
    }),
    setTimeDuration: assign({
      currentTime: ({ event }) => (event as SetTimeDuration).currentTime,
      duration: ({ event }) => (event as SetTimeDuration).duration
    }),
    setInfoOnLoad: assign({
      currentTime: ({ event }) => (event as SetInfoOnLoad).currentTime,
      duration: ({ event }) => (event as SetInfoOnLoad).duration,
      buffers: ({ event }) => (event as SetInfoOnLoad).buffers
    }),
    fadeOut,
    disconnect: ({ context }) => {
      disconnectNodes(context.nodes)
      context.mediaNode.disconnect()
    },
    clearError: assign({
      error: undefined
    }),
    setError: assign({
      error: ({ context }) => {
        const me = context.mediaNode.mediaElement
        return me.error?.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED
          ? 'Audio not found or in unsupported format'
          : me.error?.code === MediaError.MEDIA_ERR_DECODE
            ? 'Decoding error'
            : me.error?.code === MediaError.MEDIA_ERR_NETWORK
              ? 'Network error'
              : me.error?.code === MediaError.MEDIA_ERR_ABORTED
                ? 'Audio playback aborted'
                : 'unknown error'
      }
    }),
    setRate: assign({
      mediaNode: ({ context, event }) => {
        context.mediaNode.mediaElement.playbackRate = (event as SetRate).rate
        return context.mediaNode
      }
    }),
    setTime: assign({
      mediaNode: ({ context, event }) => {
        context.mediaNode.mediaElement.currentTime = (event as SetTime).time
        return context.mediaNode
      }
    }),
    blobUrlSet: assign(({ event }) => ({ blobUrl: (event as BlobUrlSet).blobUrl })),
    sendTrackInfo: sendParent(({ context }) => {
      const plState = context.playlistRef.getSnapshot()
      const m = plState.context.playlist.find(m => m.isQueued)
      // console.log('sending track info', event.type, context.mediaNode.mediaElement.paused, context.mediaNode.mediaElement.readyState)
      return {
        type: 'SEND_TRACK_INFO',
        id: context.id,
        track: {
          id: context.id,
          kind: context.kind,
          vol: context.vol,
          url: m?.uri || undefined,
          title: m ? getTitle(m) : undefined,
          playlistId: plState.context.playlistId,
          playing: context.mediaNode.mediaElement.paused
            ? false
            : context.mediaNode.mediaElement.readyState >= 2 && context.vol > 0.002
        }
      }
    }),
  },
  actors: {
    PlaylistMachine,
    SearchMachine,
    tryPlay: fromPromise(({ input }: { input: { mediaNode: MediaElementAudioSourceNode } }) => input.mediaNode.mediaElement.play().then(() => 'wtf')),
    loadSrc,
    createMediaListeners
  }
}).createMachine({
  initial: 'empty',
  context: ({ input, spawn, self }): PlayerContext => {
    const { playlistId, ...context } = input
    return Object.assign(context, {
      playlistRef: spawn('PlaylistMachine', {
        input: {
          parentRef: self,
          playlist: [],
          playlistId: playlistId || generateId(16),
          loopPlaylist: false,
          trackId: input?.id
        }
      }),
      searchRef: spawn('SearchMachine', {
        input: {
          searchOpen: false,
          searchTerm: '',
          searchKind: 'fs',
          locals: [],
          results: []
        }
      })
    })
  },
  on: {
    // RESUME: { actions: sendParent({ type: 'RESUME' }) },
    SET_VOL: { actions: [assign({ vol }), 'sendTrackInfo'] },
    SET_PAN: { actions: assign({ pan }) },
    SET_EQHI: { actions: assign({ eqHi }) },
    SET_EQMD: { actions: assign({ eqMd }) },
    SET_EQLO: { actions: assign({ eqLo }) },
    TOGGLE_M: { actions: assign({ master }) },
    TOGGLE_B: { actions: assign({ broadcast }) },
    TOGGLE_BIG: { actions: 'toggleBig' },

    SET_PROGRESS: { actions: 'setProgress' },
    SET_INFO_ON_LOAD: {
      actions: 'setInfoOnLoad',
      target: '.ready'
    },
    LOAD_MEDIA: {
      target: '.loading'
    },
    TOGGLE_LOOP: {
      actions: 'toggleLoop'
    },
    CLEAR_ERROR: { actions: 'clearError' },
    ERROR: {
      actions: 'setError',
      target: '.empty'
    },
    // BLOB_URL_CLEAR: { actions: 'blobUrlClear' },
    BLOB_URL_SET: { actions: 'blobUrlSet' },
    ANNOUNCE_DL_KEYS: { // just forward to the playlist
      actions: sendTo(({ context }) => context.playlistRef, ({ event }) => event)
    },
    DONE: {
      target: '.kill'
    }
  },
  states: {
    loading: {
      entry: 'clearError',
      invoke: {
        src: 'loadSrc',
        input: ({ context, event }) => ({
          media: (event as LoadMedia).media,
          mediaNode: context.mediaNode,
          blobUrl: context.blobUrl
        })
      }
    },
    kill: {
      entry: 'fadeOut',
      exit: sendParent(({ context }) => ({
        type: 'REMOVE_TRACK',
        id: context.id
      })),
      after: {
        110: {
          target: 'done'
        }
      }
    },
    done: {
      entry: 'disconnect',
      type: "final" as const
    },
    ready: {
      entry: 'sendTrackInfo',
      invoke: {
        src: 'createMediaListeners',
        input: ({ context }) => ({ mediaNode: context.mediaNode })
      },
      initial: 'idle',
      states: {
        idle: {
          entry: 'sendTrackInfo',
          on: {
            PLAY: {
              actions: 'resume',
              target: 'tryplay'
            }
          }
        },
        tryplay: {
          invoke: {
            src: 'tryPlay',
            input: ({ context }) => ({ mediaNode: context.mediaNode }),
            onDone: {
              target: 'playing'
            },
            onError: {
              actions: 'setError',
              target: 'idle'
            }
          }
        },
        playing: {
          entry: 'sendTrackInfo',
          on: {
            STOP: {
              actions: 'setStop',
              target: 'idle'
            },
            PAUSE: {
              actions: 'setPause',
              target: 'idle'
            },
            BUFFER_LOW: {
              target: 'buffering'
            },
            TRACK_END: {
              target: 'idle'
            },
            ERROR: {
              actions: 'setError',
              target: 'idle'
            }
          }
        },
        buffering: {
          entry: ['sendTrackInfo'],
          on: {
            PLAY: {
              target: 'playing'
            },
            ERROR: {
              actions: 'setError',
              target: 'idle'
            }
          }
        }
      },
      on: {
        SET_TIME_DURATION: {
          actions: ['setTimeDuration']
        }
      }
    },
    empty: {}
  }
})
export type PlayerService = ActorRefFrom<typeof playerMachine>
export const createPlayerInput = (id: string, ctx: AudioContext, masterGain: GainNode, broadcastGain: GainNode, playlistId?: string): PlayerInput => {
  const { nodes, values } = createNodes(ctx, true, true)

  const mediaElement = document.createElement('audio')
  const mediaNode = ctx.createMediaElementSource(mediaElement)

  // see this bullshit: https://www.w3.org/TR/webaudio/#MediaElementAudioSourceOptions-security
  mediaElement.crossOrigin = 'anonymous'
  mediaElement.preload = 'metadata' // WARNING: setting this to 'auto' makes issues with 'DOMException: The play() request was interrupted'
  connectToNodes(mediaNode, nodes)
  connectNodesToOut(nodes, masterGain, broadcastGain)

  return {
    /*
    searchOpen: true,
    searchTerm: '',
    searchKind: 'yt',
    total: 0,
    locals: {},
    results: [],
    */
    id,
    kind: 'player',
    mediaNode,
    ctx,
    currentTime: 0,
    duration: 0,
    playing: false,
    waiting: false,
    // loopPlaylist: false,
    loop: false,
    buffers: [],
    // playlist: [],
    playlistId: playlistId || generateId(16),
    nodes,
    ...values
  }
}
